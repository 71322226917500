<template>
  <div
    :class="[sizeclasses, classes, stateClasses]"
    class="relative w-full cursor-text rounded border bg-bgr px-3 transition-all duration-200"
    @click="input?.focus()"
  >
    <input
      v-bind="$attrs"
      :id="id"
      ref="input"
      :placeholder="placeholder"
      :name="name"
      :type="effectiveType"
      :value="modelValue"
      class="h-full w-full border-none p-0 text-base leading-5 outline-none ring-0 focus:outline-none focus:ring-0"
      :class="{
        valid: valid === true,
        invalid: valid === false,
        'mt-2': size !== 'sm',
        'pl-6': size === 'sm' && icon && !label,
      }"
      @input="
        $emit(
          'update:modelValue',
          ($event.target as HTMLInputElement).value,
          $event,
        )
      "
      @change="
        $emit(
          'update:modelValue',
          ($event.target as HTMLInputElement).value,
          $event,
        )
      "
      @focus="delayedResize(true)"
      @blur="delayedResize(false)"
    />

    <WebccIcon
      v-if="!label && icon && size === 'sm'"
      :name="icon"
      class="pointer-events-none absolute left-2 top-0 h-full w-4 text-txt opacity-60"
    />

    <label
      v-if="label"
      class="absolute left-0 top-1/2 w-full -translate-y-1/2 cursor-text overflow-hidden text-ellipsis whitespace-nowrap px-3 text-left text-sm font-medium text-txt-weak transition-all duration-300"
      :for="id"
      :class="{
        'top-2 translate-y-0 text-xs': onFocus || hasValue || placeholder,
      }"
    >
      <WebccIcon
        v-if="icon"
        :name="icon"
        class="pointer-events-none absolute h-full w-4 text-txt opacity-60"
        aria-hidden="true"
      />
      <span
        ref="labelText"
        v-tooltip="{
          content: $t(label as TranslationKey),
          triggers: [],
          shown: showTooltip && labelHover,
        }"
        :class="{ 'pl-6': icon }"
        @mouseover="labelHover = true"
        @mouseleave="labelHover = false"
      >
        {{ $t(label as TranslationKey) }}
        <sup v-if="required">*</sup>
      </span>
    </label>
    <div
      class="absolute right-4 top-1/2 -translate-y-1/2 flex space-x-4 items-center"
    >
      <transition name="slide" mode="out-in">
        <WebccIcon
          v-if="valid === true"
          name="site/tick"
          class="size-6 text-suc"
        />
        <WebccIcon
          v-else-if="valid === false"
          name="site/error-warning"
          class="size-6 text-err"
        />
      </transition>
      <WebccIcon
        v-if="type == 'password'"
        class="size-8 p-1 bg-bgr-100 rounded-full hover:bg-bgr-300 text-txt-weak hover:cursor-pointer"
        :name="passwordVisible ? 'feather/eye-off' : 'feather/eye'"
        @click.stop="toggleVisibility"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
type Type =
  | 'text'
  | 'password'
  | 'date'
  | 'email'
  | 'number'
  | 'url'
  | 'tel'
  | 'search'
  | 'color'

interface Props {
  required?: boolean | string
  name: string
  label?: string
  placeholder?: string
  icon?: string
  modelValue?: string | number
  size?: 'md' | 'sm'
  type?: Type
  valid?: boolean | undefined
  classes?: string
}

const props = withDefaults(defineProps<Props>(), {
  required: false,
  label: '',
  placeholder: '',
  icon: '',
  size: 'md',
  type: 'text',
  valid: undefined,
  modelValue: '',
  classes: '',
})

defineEmits<{ (e: 'update:modelValue', value: string, event: Event): void }>()

const onFocus = ref(false)
const id = ref(props.name)
const labelWidth = ref('')
const inputWidth = ref('')
const labelHover = ref(false)
const passwordVisible = ref(false)
const effectiveType: Ref<Type | undefined> = ref()

const input: Ref<HTMLInputElement | null> = ref(null)
const labelText: Ref<HTMLSpanElement | null> = ref(null)

const hasValue = computed(() => {
  return !!props.modelValue
})

const sizeclasses = computed(() => {
  switch (props.size) {
    case 'md':
      return props.label === '' ? 'h-11 text-base py-4' : 'h-14 text-base py-4'
    case 'sm':
      return 'h-10'
    default:
      return ''
  }
})

const showTooltip = computed(() => {
  return labelWidth.value > inputWidth.value
})

const stateClasses = computed(() => {
  if (props.valid === true) {
    return 'border-suc'
  }
  if (props.valid === false) {
    return 'border-err'
  }

  if (onFocus.value) {
    return 'border-txt-weak'
  }
  return 'border-txt-weaker'
})

onMounted(() => {
  handleResize()
  window.addEventListener('resize', handleResize)
  effectiveType.value = props.type
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})

function handleResize() {
  if (labelText.value && input.value) {
    labelWidth.value = labelText.value!.offsetWidth.toString()
    inputWidth.value = input.value!.offsetWidth.toString()
  }
}

function delayedResize(focus: boolean) {
  onFocus.value = focus
  setTimeout(handleResize, 310)
}

function toggleVisibility() {
  passwordVisible.value = !passwordVisible.value
  if (passwordVisible.value) {
    effectiveType.value = 'text'
  }
  if (!passwordVisible.value) {
    effectiveType.value = 'password'
  }
}
</script>

<style scoped>
.slide-enter-active {
  animation: slide 200ms ease-out;
}
.slide-leave-active {
  animation: slide 200ms ease-out reverse;
}
@keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(-50%) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
</style>
